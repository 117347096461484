<template>
    <div class="how-withdraw">
        <h1>How to withdraw balance from an advertising account / How to transfer funds from one advertising account to
            another?
        </h1>
        <ol>
            <li>In the "Accounts" section in the list of accounts, select the necessary advertising account, click on the
                icon of three vertical dots, select and click "Refund"
            </li>
            <li>Fill out the form, you can transfer the total current balance or a specific amount</li>
            <li>The amount will be returned to the total balance of the "Deposit" business account along with the commission
                amount</li>
        </ol>
        <b>
            <i>If you want to transfer the total amount from an advertising account, you will be able to do so if 24 to 48
                hours have passed from the time of stopping advertising in the advertising account.</i>
        </b>
        <b><i>Withdrawal of funds is available for Meta, TikTok.</i></b>
    </div>
</template>
<script>
export default {
    name: "howToWithDraw",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.how-withdraw {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
    h1 {
        line-height: normal;
    }

    ol,
    ul {
        list-style-position: inside;
    }
}
</style>